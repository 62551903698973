import { useEffect, useState } from "react";
import { Centre, Stat, Stats } from "../types/centre";
import ApiService from "../api/api";
import StatCard from "./StatCard";
import { format } from "date-fns";
import { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import Chart from "./Chart";
import TesterCard from "./TesterCard";
import { getCentreStats } from "../store/centreSlice";
import CalibrationCard from "./CalibrationCard";
import { useHistory } from "react-router-dom";
import { setTab } from "../store/auditSlice";

type Card = {
  label: string
  stat?: string
  action?: string | (() => void)
}

const cards: Card[] = [
  // { label: "TQI ", stat: "tqi", action: 'showGraph' },
  { label: "No. of Tests", stat: "tests", action: 'showGraph' },
  { label: "No. of Abandoned Tests", stat: "abortedTests", action: 'showGraph' },
  // { label: "Test Logs" },
  { label: "Average Test Time", stat: "averageDuration", action: 'showGraph' },
  { label: "No. of Tests <30min", stat: "shortTests", action: 'showGraph' },
  { label: "No. of Tests >60min", stat: "longTests", action: 'showGraph' },
  { label: "Re-Tests <5min", stat: "shortReTests", action: 'showGraph' },
  { label: "No. of PRS", stat: "numberOfPRS" },
  { label: "Re-Tests >9 days from original", stat: "lateReTests" },
  { label: "IP Addresses Used", stat: "ipAddressesUsed" },
  { label: "% Franchised Vehicles", stat: "franchisePercentage", action: 'showGraph' },
  { label: "No. Franchised", stat: "numFranchise" },
  { label: "No. Non Franchised", stat: "numNonFranchise" },
]

export default function CentreStats({ centre }: { centre: Centre }) {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const month = useAppSelector(state => state.centres.month)
  const allStats = useAppSelector(state => state.centres.stats)
  const [stats, setStats] = useState<Stats>()
  const [showChart, setShowChart] = useState(false)
  const [chartType, setChartType] = useState<{ type: string, name?: string }>({ type: "tests" })

  useEffect(() => {
    if (allStats) {
      const stats = allStats[centre.id]
      if (stats) {
        setStats(stats)
      }
    }
  }, [allStats, centre])

  useEffect(() => {
      dispatch(getCentreStats(centre.id))
  }, [dispatch, centre.id, month])

  const triggerChart = (type: string, name?: string) => {
    setShowChart(true)
    setChartType({ type, name })
  }

  const cardClick = (card: Card) => {
    if (card.action === 'showGraph' && card.stat) {
      const graph = card.stat === 'franchisePercentage' ? 'brands' : card.stat
      return () => triggerChart(graph!, card.label)
    }

    return undefined
  }

  return (
    <>
      {cards.map(card => {
        const statValue = stats && card.stat && stats[card.stat]?.value
        if (statValue === undefined) {
          return undefined
        }

        return (
          <StatCard
            header={card.label}
            value={statValue}
            state={(stats && card.stat && stats[card.stat]?.rating) || 'none'}
            onClick={cardClick(card)}
          />
        )
      })}
      <TesterCard {...stats?.testersRAG} />
      <CalibrationCard
        {...stats?.equipmentRAG}
        onClick={() => {
          dispatch(setTab('calibration'))
          history.push(`/centres/${centre.id}/capture`)
        }}
      />

      <Chart
        centre={centre!}
        type={chartType.type}
        name={chartType.name}
        year={format(month, 'Y')}
        open={showChart}
        setOpen={setShowChart} />
    </>
  )
}