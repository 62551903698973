import { Fragment, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, UserIcon, XIcon } from '@heroicons/react/outline'
import { classNames } from '../utils'
import { NavLink } from 'react-router-dom'
import AuthService from '../api/auth'
import { store } from '../store'
import { logout } from '../store/authSlice'
import { useHistory } from 'react-router-dom'
import useAuth from '../hooks/useAuth'

const defaultNavigation = [
  { name: 'Dashboard', href: '/', current: true, exact: true },
  { name: 'Centres', href: '/centres', current: false, exact: false },
  { name: 'Brands', href: '/brands', current: false, exact: false },
  { name: 'Users', href: '/users', current: false, exact: false },
]

const userNavigation = [
  { name: 'Your Profile', href: '/profile', nav: true },
  { name: 'Settings', href: '/settings', nav: true },
  {
    name: 'Sign out',
    href: '#',
    nav: false,
    action: async (history: any) => {
      await AuthService.logout()
      store.dispatch(logout())
      history.replace('/')
    }
  },
]

export default function NavHeader() {
  const user = useAuth()
  const history = useHistory()
  const [navigation, setNavigation] = useState(defaultNavigation)

  useEffect(() => {
    const navigation = [...defaultNavigation]

    if (user?.can('read-roles')) {
      navigation.push({ name: 'Roles', href: '/roles', current: false, exact: false })
    }

    setNavigation(navigation)
  }, [user])

  return (
    <Disclosure as="nav" className="bg-primary-500">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center">
                {/* <div className="flex-shrink-0">
                  <img
                    className="h-8 w-8"
                    src="https://tailwindui.com/img/logos/workflow-mark-white.svg"
                    alt="Workflow"
                  />
                </div> */}
                <div className="hidden md:block">
                  <div className="flex items-baseline space-x-4">
                    {navigation.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        className="px-4 py-2 rounded-md text-sm text-gray-200 hover:bg-primary-dark hover:text-white"
                        activeClassName="bg-primary-700 text-white"
                        exact={item.exact}
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  {/* <button
                    type="button"
                    className="bg-primary hover:bg-primary-dark p-2 rounded-full text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-5 w-5" aria-hidden="true" />
                  </button> */}

                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      {/* <Menu.Button className="max-w-xs bg-primary-dark rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                        <span className="sr-only">Open user menu</span>
                        <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" />
                      </Menu.Button> */}
                      <Menu.Button className="bg-primary-500 hover:bg-primary-700 p-2 rounded-full text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                        <span className="sr-only">Open user menu</span>
                        <UserIcon className="h-5 w-5" aria-hidden="true" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="z-50 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => {
                          if (item.nav) {
                            return (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <NavLink
                                    key={item.name}
                                    to={item.href}
                                    className="block px-4 py-2 text-sm text-gray-700"
                                    activeClassName="bg-gray-100"
                                  >
                                    {item.name}
                                  </NavLink>
                                )}
                              </Menu.Item>
                            )
                          }

                          return (
                            <a
                              key={item.name}
                              href={item.href}
                              className="block px-4 py-2 text-sm text-gray-700"
                              onClick={() => { item.action && item.action(history) }}
                            >
                              {item.name}
                            </a>
                          )
                        })}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="bg-primary-700 inline-flex items-center justify-center p-2 rounded-md text-gray-200 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-200 hover:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-700">
              <div className="flex items-center px-5">
                {/* <div className="flex-shrink-0">
                  <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                </div> */}
                <div>
                  <div className="text-base font-medium leading-none text-white">{user?.name}</div>
                  <div className="text-sm font-medium leading-none text-gray-200">{user?.email}</div>
                </div>
                <button
                  type="button"
                  className="ml-auto bg-primary-700 flex-shrink-0 p-1 rounded-full text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-3 px-2 space-y-1">
                {userNavigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-200 hover:text-white hover:bg-gray-700"
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}