import { classNames } from "../utils";
import { ReactComponent as SpeedoRed } from '../assets/SpeedoRed.svg'
import { ReactComponent as SpeedoAmber } from '../assets/SpeedoAmber.svg'
import { ReactComponent as SpeedoGreen } from '../assets/SpeedoGreen.svg'

type Props = {
  header: string
  value?: string | number
  state?: 'none' | 'green' | 'amber' | 'red'
  onClick?: () => void
}

export default function StatCard({ header, value = '', state = 'none', onClick }: Props) {
  const backgroundColor = () => {
    switch (state) {
      case 'green':
        return 'bg-green-200'
      case 'amber':
        return 'bg-yellow-200'
      case 'red':
        return 'bg-red-200'
      default:
        return 'bg-white'
    }
  }

  const speedo = () => {
    switch (state) {
      case 'green':
        return <SpeedoGreen />
      case 'amber':
        return <SpeedoAmber />
      case 'red':
        return <SpeedoRed />
    }
  }

  return (
    <div
      className={classNames(
        "h-40 bg-white overflow-hidden shadow-md rounded-lg transition-shadow duration-300",
        backgroundColor(),
        onClick ? "cursor-pointer hover:shadow-xl" : ""
      )}
      onClick={onClick}
    >
      <div className="h-full flex flex-col p-4">
        <h5 className="mb-6 text-2xl font-header">{header}</h5>
        <div className="mt-auto flex">
          {speedo()}
          <h3 className="ml-auto mt-auto self-end text-6xl font-medium">{value}</h3>
        </div>
      </div>
    </div>
  )
}